var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"10px 0"}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('fw-title',{staticStyle:{"padding-top":"20px"},attrs:{"titles":"订单明细"}})],1),_c('el-col',{staticStyle:{"margin":"15px"},attrs:{"span":24}},[(_vm.tableList.length > 0)?_c('div',[_c('span',{staticStyle:{"color":"#666"}},[_vm._v("本次申请开票金额 ：")]),_c('span',{staticStyle:{"color":"#f74848","font-weight":"bold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.totalPrice))+" (大写:"+_vm._s(_vm._f("dealBigMoney1")(_vm.totalPrice))+") ")])]):_c('div',[_vm._v(" ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}]},[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",staticStyle:{"min-height":"10vh"},attrs:{"stripe":"","highlight-hover-row":"","size":"small","id":"toolbar_demo3","row-config":{ height: 120 },"data":_vm.tableList}},[_c('vxe-column',{attrs:{"fixed":"left","type":"seq","title":"序号","width":"50","align":"center","field":"xuhao"}}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("订单编号")]),_c('p',[_vm._v("订单业务状态")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.serial_number}},[_vm._v(" "+_vm._s(row.serial_number || "--")+" ")]),(row.order_status == 10)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("待收单")]):(row.order_status == 20)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(" 待付款 ")]):(row.order_status == 30)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v(" 已付款 ")]):(row.order_status == 40)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(" 待发货 ")]):(row.order_status == 50)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(" 待收货 ")]):(row.order_status == 60)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(" 已收货 ")]):(row.order_status == 70)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v(" 已取消 ")]):(row.order_status == 80)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v(" 已取消 ")]):(row.order_status == 90)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v(" 已删除 ")]):_c('p',[_vm._v(" -- ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("付款方式")]),_c('p',[_vm._v("来源")]),_c('p',[_vm._v("生效时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_c('span',{staticClass:"colors",staticStyle:{"background-color":"#4387F8"}}),_c('span',{staticStyle:{"color":"#4387F8"}},[_vm._v(" "+_vm._s((row.order_model == 1 ? "先款后货" : "先货后款") || "--")+" ")])]),_c('div',[(row.source == 1)?[_vm._v(" 自主新增 ")]:(row.source == 2)?[_vm._v(" 企业购物车 ")]:(row.source == 3)?[_vm._v(" 工业电商 ")]:[_vm._v(" -- ")]],2),_c('p',{staticClass:"ellipsisText ",attrs:{"title":row.created_at || '--'}},[_vm._v(" "+_vm._s(row.created_at || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("采购方名称")]),_c('p',[_vm._v("统一社会信用代码")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":row.buyer_company_name || '--'}},[_vm._v(" "+_vm._s(row.buyer_company_name || "--")+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":row.buyer_company_code || '--'}},[_vm._v(" "+_vm._s(row.buyer_company_code || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("销售方名称")]),_c('p',[_vm._v("统一社会信用代码")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":row.seller_company_name || '--'}},[_vm._v(" "+_vm._s(row.seller_company_name || "--")+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":row.seller_company_code || '--'}},[_vm._v(" "+_vm._s(row.seller_company_code || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("订单总额")]),_c('p',[_vm._v("付款金额")]),_c('p',[_vm._v("发货金额")]),_c('p',[_vm._v("收货金额")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.order_amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.order_amount))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.pay_amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.pay_amount))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.send_amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.send_amount))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.receipt_amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.receipt_amount))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("订单本次开票金额")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.invoice_amount}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(row.invoice_amount))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }