<template>
  <div id="main_MyOrder_sign" style="width:1200px; margin:0 auto">
    <el-container style="height: 100%" class="head_info_bar" v-if="dataInfo">
      <el-header height="auto">
        <fw-header titles="发票详情" />
      </el-header>
      <el-main>
        <div style="padding: 10px 15px">
          <fw-basemsg :obj="obj2" :model="model2" :showTexts="true" :approvel="false" />

          <fw-title titles="基本信息" style="padding-top: 15px" />
          <el-row class="basmsg" v-if="dataInfo">
            <el-col :span="24" class="divs">申请单号：{{ dataInfo.apply_serial_number }}</el-col>
            <el-col :span="24" class="divs">
              购买方名称：{{ dataInfo.order_list && dataInfo.order_list[0].buyer_company_name }}
            </el-col>
            <el-col :span="24" class="divs">
              统一社会信用代码：{{
                dataInfo.order_list && dataInfo.order_list[0].buyer_company_code
              }}
            </el-col>

            <el-col :span="8" class="divs">
              可开票金额：
              <span style="font-weight:bold;color:#f74848;font-size:18px">
                {{ dataInfo.order_list && dataInfo.order_list[0].not_invoice_amount | formatMoney }}
                (大写:{{
                  dataInfo.order_list && dataInfo.order_list[0].not_invoice_amount | dealBigMoney1
                }})
              </span>
            </el-col>
            <el-col :span="8" class="divs">
              未收票金额：
              <span style="font-weight:bold;color:#f74848;font-size:18px">
                {{
                  dataInfo.order_list && dataInfo.order_list[0].not_invoiced_amount | formatMoney
                }}(大写:{{
                  dataInfo.order_list && dataInfo.order_list[0].not_invoiced_amount | dealBigMoney1
                }})
              </span>
            </el-col>
            <el-col :span="8" class="divs">
              累计开票金额：
              <span style="font-weight:bold;color:#f74848;font-size:18px">
                {{
                  dataInfo.order_list && dataInfo.order_list[0].order_amount | formatMoney
                }}(大写:{{
                  dataInfo.order_list && dataInfo.order_list[0].order_amount | dealBigMoney1
                }})
              </span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <fw-title titles="购货单位" style="padding-top: 15px" />

              <div class="bar">
                <div class="bar_col" style="align-items: flex-start;">
                  <div class="divs">
                    购货单位
                  </div>
                  <div>
                    <div class="choseGongji">
                      <h4 class="ellipsisText " :title="bankAccInfo.buyer.name || '--'">
                        {{ bankAccInfo.buyer.name || "--" }}
                      </h4>
                      <div>
                        <div>
                          <p>纳税人识别号</p>
                          <p>地址</p>
                          <p>电话</p>
                          <p>开户行及账号</p>
                        </div>
                        <div>
                          <p class="ellipsisText " :title="bankAccInfo.buyer.code || '--'">
                            {{ bankAccInfo.buyer.code }}
                          </p>
                          <p class="ellipsisText " :title="bankAccInfo.buyer.address || '--'">
                            {{ bankAccInfo.buyer.address || "--" }}
                          </p>
                          <p class="ellipsisText " :title="bankAccInfo.buyer.tel || '--'">
                            {{ bankAccInfo.buyer.tel || "--" }}
                          </p>
                          <p
                            class="ellipsisText "
                            :title="
                              `${bankAccInfo.buyer.bank || '--'} / ${bankAccInfo.buyer.account ||
                                '--'}`
                            "
                          >
                            {{
                              `${bankAccInfo.buyer.bank || "--"} / ${bankAccInfo.buyer.account ||
                                "--"}`
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <fw-title titles="销售单位" style="padding-top: 15px" />
              <div class="bar">
                <div class="bar_col" style="align-items: flex-start;">
                  <div class="divs">
                    销售单位
                  </div>
                  <div>
                    <div class="choseGongji">
                      <h4 class="ellipsisText " :title="bankAccInfo.seller.name || '--'">
                        {{ bankAccInfo.seller.name || "--" }}
                      </h4>
                      <div>
                        <div>
                          <p>纳税人识别号</p>
                          <p>地址</p>
                          <p>电话</p>
                          <p>开户行及账号</p>
                        </div>
                        <div>
                          <p class="ellipsisText " :title="bankAccInfo.seller.code || '--'">
                            {{ bankAccInfo.seller.code || "--" }}
                          </p>
                          <p class="ellipsisText " :title="bankAccInfo.seller.address || '--'">
                            {{ bankAccInfo.seller.address || "--" }}
                          </p>
                          <p class="ellipsisText " :title="bankAccInfo.seller.tel || '--'">
                            {{ bankAccInfo.seller.tel || "--" }}
                          </p>
                          <p
                            class="ellipsisText "
                            :title="
                              `${bankAccInfo.seller.bank || '--'} / ${bankAccInfo.seller.account ||
                                '--'}`
                            "
                          >
                            {{
                              `${bankAccInfo.seller.bank || "--"} / ${bankAccInfo.seller.account ||
                                "--"}`
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <fw-title titles="开票联系地址" style="padding-top: 15px" />
            <el-col :span="18" style="margin-top:15px">
              <vxe-table
                style="min-height:10vh"
                class="mytable-scrollbar"
                stripe
                highlight-hover-row
                size="small"
                :border="false"
                ref="xTable1"
                id="toolbar_demo3"
                :data="addressArr"
                row-key
                auto-resize
              >
                <vxe-column min-width="180" fixed="left">
                  <template #header>
                    <p>类型</p>
                  </template>
                  <template #default="{ row }">
                    <p class="ellipsisText" :title="row.type || '--'">
                      {{ row.type || "--" }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="140">
                  <template #header>
                    <p>
                      <span style="color:red">*</span>
                      联系人
                    </p>
                  </template>
                  <template #default="{row}">
                    <template>
                      {{ row.name || "--" }}
                    </template>
                  </template>
                </vxe-column>
                <vxe-column min-width="260">
                  <template #header>
                    <p>
                      联系地址
                    </p>
                  </template>
                  <template #default="{row}">
                    {{ renderAddress(row.address) }}
                  </template>
                </vxe-column>
                <vxe-column min-width="140">
                  <template #header>
                    <p>
                      邮箱
                    </p>
                  </template>
                  <template #default="{row}">
                    {{ row.email || "--" }}
                  </template>
                </vxe-column>
              </vxe-table>
            </el-col>
          </el-row>
          <div>
            <!-- 订单明细-->
            <orderDetailTable :tableList="tableList" />
          </div>
          <div>
            <fw-title titles="发票明细" style="padding-top: 15px" />
            <el-row>
              <el-col :span="24">
                <div v-if="tableList.length > 0" style="margin:15px">
                  <span style="color:#666;">发票总金额 ：</span>
                  <span style="color:#f74848;font-weight:bold;font-size:16px">
                    {{ totalPrice | formatMoney }} (大写:{{ totalPrice | dealBigMoney1 }})
                  </span>
                </div>
                <div v-else>&nbsp;</div>
              </el-col>
            </el-row>
            <div>
              <vxe-table
                style="min-height:10vh"
                class="mytable-scrollbar"
                stripe
                highlight-hover-row
                size="small"
                id="toolbar_demo3"
                :row-config="{ height: 120 }"
                ref="xTable1"
                :data="invoiceTable"
              >
                <vxe-column
                  fixed="left"
                  type="seq"
                  title="序号"
                  width="50"
                  align="center"
                  field="xuhao"
                ></vxe-column>
                <vxe-column min-width="140">
                  <template #header>
                    <p>发票类型</p>
                    <p>发票种类</p>
                    <p>验真状态</p>
                  </template>
                  <template #default="{ row }">
                    <p class="ellipsisText " :title="row.type || '--'">
                      {{ row.type || "--" }}
                    </p>
                    <p class="ellipsisText " :title="row.invoice_class || '--'">
                      {{ row.invoice_class || "--" }}
                    </p>
                    <el-tag type="warning" v-if="row.status_v == 0">未验真</el-tag>
                    <el-tag type="success" v-if="row.status_v == 1">验真通过</el-tag>
                    <el-tag type="danger" v-if="row.status_v == 2">验真失败</el-tag>
                  </template>
                </vxe-column>
                <vxe-column min-width="180">
                  <template #header>
                    <p>采购方名称 - 统一社会信用代码</p>
                    <p>销售方名称 - 统一社会信用代码</p>
                  </template>
                  <template #default="{ row }">
                    <p
                      class="ellipsisText "
                      :title="
                        `${row.purchaser_name || '--'} - ${row.purchaser_register_num || '--'}`
                      "
                    >
                      {{ `${row.purchaser_name || "--"} - ${row.purchaser_register_num || "--"}` }}
                    </p>
                    <p
                      class="ellipsisText "
                      :title="`${row.seller_name || '--'} - ${row.seller_register_num || '--'}`"
                    >
                      {{ `${row.seller_name || "--"} - ${row.seller_register_num || "--"}` }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="140">
                  <template #header>
                    <p>发票号码</p>
                    <p>发票代码</p>
                    <p>开票日期</p>
                  </template>
                  <template #default="{ row }">
                    <p class="ellipsisText " :title="row.invoice_num || '--'">
                      {{ row.invoice_num || "--" }}
                    </p>
                    <p class="ellipsisText " :title="row.invoice_code || '--'">
                      {{ row.invoice_code || "--" }}
                    </p>
                    <p class="ellipsisText " :title="row.invoice_date || '--'">
                      {{ row.invoice_date || "--" }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="140">
                  <template #header>
                    <p>金额合计</p>
                    <p>税率</p>
                    <p>税额</p>
                  </template>
                  <template #default="{ row }">
                    <p class="ellipsisText " :title="row.total_amount || '--'">
                      {{ row.total_amount | formatMoney }}
                    </p>
                    <p class="ellipsisText " :title="row.tax_rate || '--'">
                      {{ row.tax_rate || "0.00" }}
                    </p>
                    <p class="ellipsisText " :title="row.tax || '--'">
                      {{ row.tax | formatMoney }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="140">
                  <template #header>
                    <p>价税合计</p>
                    <p>不可抵扣金额</p>
                    <p>总金额</p>
                  </template>
                  <template #default="{ row }">
                    <p class="ellipsisText " :title="row.amount_in_figuers || '--'">
                      {{ row.amount_in_figuers | formatMoney }}
                    </p>
                    <p class="ellipsisText " :title="row.not_amount || '--'">
                      {{ row.not_amount | formatMoney }}
                    </p>
                    <p class="ellipsisText " :title="row.invoice_amount || '--'">
                      {{ row.invoice_amount | formatMoney }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="200">
                  <template #header>
                    <p>发票原件</p>
                  </template>
                  <template #default="{ row }">
                    <template v-if="row.invoice_file">
                      <div
                        style="width: 80%; margin-top: 20px"
                        v-for="(item, index) in row.invoice_file"
                        :key="index"
                      >
                        <fw-upload :model="item" :view="true" />
                      </div>
                    </template>
                    <template v-else>
                      --
                    </template>
                  </template>
                </vxe-column>
                <vxe-column min-width="140">
                  <template #header>
                    <p>关联订单</p>
                  </template>
                  <template #default="{ row }">
                    <div v-for="(item, index) in row.order_list" :key="index">
                      <p
                        class="ellipsisText"
                        :title="`${item.serial_number || '--'}`"
                        v-if="index < 2"
                      >
                        {{ `${item.serial_number || "--"}` }}
                      </p>
                    </div>
                    <el-popover
                      v-if="row.order_list.length > 2"
                      placement="right"
                      width="200"
                      trigger="hover"
                    >
                      <div style="padding: 10px">
                        <p
                          v-for="(item, index) in row.order_list"
                          :key="index"
                          :title="`${item.serial_number || '--'} `"
                        >
                          {{ `${item.serial_number || "--"} ` }}
                        </p>
                      </div>
                      <span slot="reference">...</span>
                    </el-popover>
                  </template>
                </vxe-column>
              </vxe-table>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer style="margin: 30px 0; height: auto">
        <el-row>
          <el-col align="center" :span="24">
            <el-button style="width: 88px" size="small" @click="$router.go(-1)">取消</el-button>
            <template v-if="dataInfo._oper.length > 0">
              <span v-for="(item, index) in dataInfo._oper" :key="index">
                <el-button
                  style="width: 88px;"
                  size="small"
                  @click="submit(0)"
                  type="danger"
                  plain
                  v-if="item.name == '拒收'"
                >
                  拒收
                </el-button>
                <el-button
                  style="width: 88px;margin:0 10px"
                  size="small"
                  type="primary"
                  @click="submit(1)"
                  v-if="item.name == '签收'"
                >
                  签收
                </el-button>
              </span>
            </template>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
    <fw-choseticket ref="fwchoseticket" />
  </div>
</template>

<script>
import orderDetailTable from "./components/orderDetailTable.vue";
export default {
  name: "",
  components: {
    orderDetailTable,
  },
  props: {},
  created() {},
  mounted() {
    this.getBasicInfo();
  },
  data() {
    return {
      dataInfo: null,
      addressArr: [
        {
          type: "收票人",
          name: "", //联系人名称
          address: {
            province: "",
            city: "",
            area: "",
            address: "",
            lat: "",
            lng: "",
          }, //联系人地址
          email: "", //邮箱
          is_input: true,
        },
        {
          type: "寄票人",
          name: "", //联系人名称
          address: {
            province: "",
            city: "",
            area: "",
            address: "",
            lat: "",
            lng: "",
          }, //联系人地址
          email: "", //邮箱
          is_input: true,
        },
      ], //联系人地址

      tableList: [],
      invoiceTable: [],
      model2: {
        text: "无",
        color: "#000",
      },
      obj2: {
        name: "",
        array: [
          { name: "集中开票单号：", value: "--" },
          { name: "开票方式：", value: "--" },
          { name: "申请人 | 申请时间：", value: "--" },
          { name: "开票人 | 开票时间：", value: "--" },
          { name: "签收人 | 签收时间：", value: "--" },
          { name: "拒签收人 | 拒签收时间：", value: "--" },
          { name: "取消人 | 取消时间：", value: "--" },
        ],
      },
      bankAccInfo: {
        buyer: {
          name: "",
          code: "",
          address: "",
          tel: "",
          account: "",
          bank: "",
        },
        seller: {
          name: "",
          code: "",
          address: "",
          tel: "",
          account: "",
          bank: "",
        },
      },
    };
  },
  watch: {},
  computed: {
    renderAddress() {
      return obj => {
        if (obj) {
          let msg = obj.province + obj.city + obj.area + obj.address;
          return msg;
        } else {
          return "--";
        }
      };
    },
    totalPrice() {
      let sum = this.invoiceTable.reduce(function(total, item) {
        return total + Number(item.invoice_amount);
      }, 0);
      return sum;
    },
  },
  methods: {
    getBasicInfo() {
      this.$axios
        .get(`/shop/public/common/personal/sign_detail`, { id: Number(this.$route.query.id) })
        .then(res => {
          if (res.data) {
            console.log(res.data);
            let data = res.data;
            this.dataInfo = data;
            this.obj2.array[0].value = data.apply_serial_number || "--";
            this.obj2.array[1].value = data.invoice_way == 1 ? "线上" : "线下";
            this.obj2.array[2].value = `${data.apply_by || "--"}  |  ${data.apply_at || "--"}`;
            this.obj2.array[3].value = `${data.invoiced_by || "--"}  |  ${data.invoiced_at ||
              "--"}`;
            this.obj2.array[4].value = `${data.signed_by || "--"}  |  ${data.signed_at || "--"}`;
            this.obj2.array[5].value = `${data.rejected_by || "--"}  |  ${data.rejected_at ||
              "--"}`;
            this.obj2.array[6].value = `${data.cancel_by || "--"}  |  ${data.cancel_at || "--"}`;
            if (data.sign_status == 0) {
              this.model2.text = "待签收";
              this.model2.color = "#e6a23c";
            } else if (data.sign_status == 1) {
              this.model2.text = "已签收";
              this.model2.color = "#7ac756";
            } else if (data.sign_status == 2) {
              this.model2.text = "拒收";
              this.model2.color = "#ff2525";
            }
            //开票联系地址
            this.addressArr[1].name = data.sender_info.name;
            this.addressArr[1].address = data.sender_info.address;
            this.addressArr[1].email = data.sender_info.email;
            this.addressArr[0].name = data.invoice_info.name;
            this.addressArr[0].address = data.invoice_info.address;
            this.addressArr[0].email = data.invoice_info.email;

            if (data.buyer_info) {
              this.bankAccInfo.buyer = data.buyer_info;
            }
            if (data.seller_info) {
              this.bankAccInfo.seller = data.seller_info;
            }
            this.tableList = data.order_list;
            let invObj = {
              purchaser_name: data.order_list && data.order_list[0].buyer_company_name,
              purchaser_register_num: data.order_list && data.order_list[0].buyer_company_code,
              seller_name: data.order_list && data.order_list[0].seller_company_name,
              seller_register_num: data.order_list && data.order_list[0].seller_company_code,
              ...data,
            };
            this.invoiceTable.push(invObj);
          }
        });
    },

    async submit(num) {
      let params = {
        order_invoice_id: this.dataInfo.id, //订单开票记录id
        opinion: "", //签收意见
      };
      let opinion = null;
      let keepGo = true;
      if (num == 0) {
        await this.$prompt(
          `请输入${num == 0 ? "拒收" : "签收"}意见`,
          `${num == 0 ? "拒收" : "签收"}意见`,
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputType: "textarea",
            closeOnClickModal: false,
            showClose: false,
            closeOnPressEscape: false,
          }
        )
          .then(({ value }) => {
            opinion = value;
          })
          .catch(() => {
            keepGo = false;
          });
      }

      params.opinion = opinion || "";
      if (!keepGo) {
        return;
      }
      let api = "";
      if (num == 0) {
        api = "/shop/public/common/personal/rejection";
      } else {
        api = "/shop/public/common/personal/sign";
      }
      this.$axios.putJSON(api, params).then(res => {
        if (res.code == 200) {
          this.$message.success("提交成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 800);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#main_MyOrder_sign {
  padding-bottom: 1px;
  box-sizing: border-box;
  height: 90vh;
  .zone {
    min-height: 10vh;
    height: auto;
  }
}
.head_info_bar {
  background-color: #fff;
  padding: 0 15px;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.choseGongji {
  margin: 12px 12px 12px 0;
  position: relative;
  display: inline-block;
  width: 80%;
  margin-right: 3%;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #fafafa;
  & > p {
    color: #0088fe;
    line-height: 30px !important;
  }
  p {
    line-height: 24px;
  }
  & > i {
    position: absolute;
    top: -5px;
    right: -5px;
    color: #ccc;
    cursor: pointer;
  }

  & > div {
    display: flex;
    & > div:nth-child(1) {
      width: 100px;
    }
    & > div:nth-child(2) {
      overflow: hidden;
      flex: 1;
      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.bar {
  background-color: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .bar_col {
    display: flex;
    align-items: center;
    line-height: 35px;
    .divs {
      display: flex;
      align-items: center;
      min-width: 100px;
      i {
        margin: 0 5px;
      }
    }
    > div:last-child {
      flex: 1;
      word-wrap: break-word; /* 当单词太长时自动换行 */
      word-break: break-all; /* 使单词在任意字符内换行，避免单词被截断 */
    }
  }
}
.act-btn {
  margin-right: 5px;
  color: #66b1fc;
}
.basmsg {
  position: relative;
  background-color: #fafafa;
  padding: 15px 25px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .el-col {
    margin-bottom: 10px;
  }
  .divs {
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .showStatus {
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
.colors2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>
